export const emailStructure = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const phoneNumberStructure = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/

export const passwordStructure = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/

export const numberOnly = /^[0-9]*$/

export const naira = "NGN"

export const dollar = "USD"

export const STORE_USER_KEY = "GRO"

export const fcmb = "FCMB"

export const STORE_BOOKING_KEY = "create-investment"

export const FCMB_BANKCODE = "214"

export const topUpOptions = {
  0: "MONTHLY",
  1: "QUARTERLY",
  2: "BIANNUALLY",
  3: "NONE",
  4: "SEMI ANNUALLY",
}

export const INVESTMENTS = "investments"

export const SAVINGS = "savings"

export const unverified_GRO_user = 61

export const unverified_INEST_user = 11
